<template>
  <b-form-group :label="`${filter.label} ${type==='entity' ? 'equals' : 'in'}`">
    <v-select
      :value="value"
      :label="filter.filterable.label"
      :options="data"
      :multiple="type==='multientity'"
      class="select-size-sm"
      @input="onInput"
      @search="onChange"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import generateGraphQLQuery from '@/plugins/gqlGenerator'

export default {
  components: {
    BFormGroup, vSelect,
  },
  props: {
    type: String,
    filter: Object,
  },
  data() {
    return {
      data: [],
      searchValue: null,
      value: null,
    }
  },
  computed: {
    generatedQuery() {
      const whereFilter = this.searchValue ? [{ name: this.filter.filterable.search, value: this.searchValue }] : []
      const query = [{}]
      query[0][this.filter.filterable.queryType] = {
        args: [
          { name: 'limit', value: '$limit' },
          { name: 'where', value: whereFilter },
        ],
        fields: [
          {
            nodes: {
              fields: [this.filter.filterable.label, this.filter.filterable.value],
            },
          },
        ],
      }

      return query
    },
  },
  watch: {
    searchValue: {
      immediate: true,
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        this.reloadAll()
      }, 500),
    },
  },
  beforeMount() {
    if (this.filter.objectValue) {
      this.value = this.filter.objectValue
    }
  },
  mounted() {
    this.reloadAll()
  },
  methods: {
    onInput(items) {
      this.value = items
      if (this.type === 'multientity') {
        this.filter.value = items.map(item => item[this.filter.filterable.value])
        this.filter.objectValue = items
        return
      }
      this.filter.value = items === null ? null : items[this.filter.filterable.value]
      this.filter.objectValue = items
    },
    onChange(search) {
      this.searchValue = search
    },
    reloadAll() {
      this.loading = true
      this.$apollo.query({
        query: generateGraphQLQuery(this.generatedQuery, `get${this.filter.filterable.queryType}`, { limit: 'Int' }),
        variables: {
          limit: 15,
        },
      }).then(result => {
        this.data = result.data[this.filter.filterable.queryType].nodes
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
