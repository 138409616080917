const getAccountExtraTags = item => {
  const tags = []
  if (item.phase_move_check !== null) tags.push(`Cron check ${item.phase_move_check}`)
  if (item.equity_check !== null) tags.push(`AVENTUS check ${item.equity_check}`)
  if (item.risk_score_results && Object.keys(item.risk_score_results).includes('pass_with_one_trade')) tags.push({ variant: 'light-danger', name: 'PASS WITH ONE TRADE' })
  if (item.risk_score_results && Object.keys(item.risk_score_results).includes('news_trading_rule')) tags.push({ variant: 'light-danger', name: '! NEWS TRADING !' })
  if (item.risk_score_results && Object.keys(item.risk_score_results).includes('equity_rule')) tags.push({ variant: 'light-warning', name: 'BREAK 2.5% RULE' })

  return tags
}

export { getAccountExtraTags }
