<template>
  <b-button
    size="sm"
    class="mr-2"
    :variant="`${row.detailsShowing ? 'secondary' : 'outline-secondary'}`"
    @click="row.toggleDetails"
  >
    {{ row.detailsShowing ? hideText : showText }} Details
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    row: Object,
    showText: {
      type: String,
      default: 'Show',
    },
    hideText: {
      type: String,
      default: 'Hide',
    },
  },
}
</script>
