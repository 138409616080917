<template>
  <b-overlay
    :show="isBusy"
    rounded="sm"
  >
    <b-form @submit="onSubmit">
      <slot />
      <hr>
      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          @click="onSubmit"
        >
          Save
        </b-button>
        <slot
          name="right-button"
          class="float-right ml-5"
        />
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import { BForm, BButton, BOverlay } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { get } from 'vuex-pathify'
import store from '@/store'

export default {
  components: {
    BForm,
    BButton,
    BOverlay,
  },
  props: {
    moduleName: String,
    id: {
      type: String,
      required: false,
    },
    model: Object,
    beforeSubmit: {
      required: false,
      type: Function,
    },
    automaticReload: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  beforeCreate() {
    const { moduleName } = this.$options.propsData
    if (!this.$options.computed) {
      this.$options.computed = {}
    }
    Object.assign(this.$options.computed, {
      ...get(moduleName, ['isBusy']),
    })
  },
  methods: {
    onSubmit() {
      if (this.beforeSubmit && !this.beforeSubmit()) {
        return
      }
      const payload = {
        id: this.id,
        data: this.model,
        autoReload: this.automaticReload,
      }
      store.dispatch(`${this.moduleName}/${this.id ? 'update' : 'create'}`, payload).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: 'Entity edit was successful',
            variant: 'success',
          },
        })
        if (this.automaticReload) {
          this.$store.dispatch(`${this.moduleName}/reloadAll`)
        }
        this.$emit('submit', response)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CheckCircleIcon',
            text: 'An error occurred during entity edit',
            variant: 'danger',
          },
        })
        this.$emit('error', error)
      })
    },
  },
}
</script>
