<template>
  <b-form-group :label="`${filter.label} in range`">
    <b-input-group class="input-group-merge">
      <b-form-input
        v-model="from"
        type="text"
        size="sm"
        @input="onInput"
      />
      <feather-icon
        icon="MinusIcon"
        class="separator"
        size="25"
      />
      <b-form-input
        v-model="to"
        type="text"
        size="sm"
        @input="onInput"
      />
      <b-input-group-append is-text>
        <feather-icon
          v-if="from !== null || to !== null"
          icon="XIcon"
          class="cursor-pointer"
          @click="onReset"
        />
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormInput, BInputGroup, BFormGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput, BInputGroup, BFormGroup, BInputGroupAppend,
  },
  props: {
    type: String,
    filter: Object,
  },
  data() {
    return {
      from: null,
      to: null,
    }
  },
  watch: {
    filter() {
      this.from = this.filter.value || Array.isArray(this.filter.value) ? this.filter.value[0] : null
      this.to = this.filter.value || Array.isArray(this.filter.value) ? this.filter.value[1] : null
    },
  },
  mounted() {
    this.from = this.filter.value || Array.isArray(this.filter.value) ? this.filter.value[0] : null
    this.to = this.filter.value || Array.isArray(this.filter.value) ? this.filter.value[1] : null
  },
  methods: {
    onInput() {
      const value = this.from || this.to ? [this.from, this.to] : null
      this.$emit('input', value)
    },
    onReset() {
      this.from = null
      this.to = null
      this.$emit('input', null)
    },
  },
}
</script>

<style scoped>
.separator {
  display: block;
  position: absolute;
  left: calc(50% - 40px);
  z-index: 10;
  width: 20px;
  height: 100%;
}
</style>
