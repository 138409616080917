import gql from 'graphql-tag'

const formatArguments = args => {
  const formattedArgs = args.map(arg => {
    if (typeof arg.value === 'object') {
      return `${arg.name}: {${formatArguments(arg.value)}}`
    }
    const argValue = typeof arg.value === 'string' && arg.value.startsWith('$') ? arg.value : JSON.stringify(arg.value)
    return `${arg.name}: ${argValue}`
  }).join(', ')

  return formattedArgs
}
const generateFields = fields => {
  // eslint-disable-next-line array-callback-return
  const fieldList = fields.map(field => {
    if (typeof field === 'string') {
      return `${field}`
    } if (typeof field === 'object') {
      const fieldName = Object.keys(field)[0]
      const fieldArgs = field[fieldName].args
      const nestedFields = field[fieldName].fields

      let query = `${fieldName}`
      if (fieldArgs && fieldArgs.length > 0) {
        const formattedArgs = formatArguments(fieldArgs)

        query += `(${formattedArgs})`
      }

      if (nestedFields && nestedFields.length > 0) {
        const nestedQuery = generateFields(nestedFields)
        query += nestedQuery
      }

      return query
    }
  }).join('\n')

  const query = `{
    ${fieldList}
  }`

  return query
}

const generateGraphQLQuery = (fields, queryName, variables = null) => {
  const formattedVariables = variables ? `(${Object.keys(variables).map(item => `$${item}: ${variables[item]}`).join(', ')})` : ''

  let query = `
    query ${queryName}${formattedVariables}
    ${generateFields(fields)}
  `
  query = query.replace(/[\r]+| {2,}/g, '')

  return gql`${query}`
}

export default generateGraphQLQuery
