import axiosInstance from '@/plugins/axios'

export default {
  activateAccount(id) {
    const url = `admin/pandadoc/activate/${id}`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  postSendContract(accountId) {
    const url = `admin/pandadoc/create/${accountId}`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  downloadAgreement(accountId, email, login) {
    const url = `admin/pandadoc/download/${accountId}/`

    return axiosInstance.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${email}-${login}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  },
}
