import { formatDateTime } from '@/plugins/formaters'

const getUserExtraTags = item => {
  const tags = []
  if (item.kyc_at) tags.push(`Verified ${formatDateTime(item.kyc_at)}`)
  if (item.kyc_at && item.verification_data === null) tags.push(`Verified by support ${formatDateTime(item.kyc_at)}`)
  if (item.purchase_disabled) tags.push({ name: 'Purchase disabled', variant: 'light-danger' })
  return tags
}

export { getUserExtraTags }
