<template>
  <div v-show="showTags">
    <b-badge
      v-for="(item, index) in visibleTags"
      :key="index"
      v-b-tooltip.hover.top="loadingAssigner ? 'loading' : item.assigner ? `${ item.assigner.email} | ${item.tag.description}` : `undefined | ${item.tag.description}`"
      :variant="item.tag.color !== 'dark' ? `light-${item.tag.color}` : item.tag.color"
      @mouseenter="getAssignerEmail(item.assigner, item)"
    >
      {{ item.tag.name }} | {{ formatDateTime(item.createdAt) }}
    </b-badge>
    {{ extrasTags.length && visibleTags.length ? '|' : '' }}
    <b-badge
      v-for="(item, key) in extrasTags"
      :key="key+item"
      :variant="typeof item === 'string' ? extrasVariant : item.variant"
    >
      {{ typeof item === "string" ? item : item.name }}
    </b-badge>
    <!--   Hidden tags   -->
    <b-button
      v-if="invisibleTags.length && showHiddenByToggle"
      variant="outline-primary"
      class="btn-sm btn-icon"
      size="sm"
      @click="showHidden = !showHidden"
    >
      <feather-icon
        :icon="showHidden ? 'ArrowUpIcon' : 'ArrowDownIcon'"
        size="10"
      />
    </b-button>
    <div v-if="showHidden || !showHiddenByToggle">
      <b-badge
        v-for="(item, index) in invisibleTags"
        :key="index"
        v-b-tooltip.hover.top="loadingAssigner ? 'loading' : item.assigner ? `${ item.assigner.email} | ${item.tag.description}` : `undefined | ${item.tag.description}`"
        :variant="item.tag.color !== 'dark' ? `light-${item.tag.color}` : item.tag.color"
        @mouseenter="getAssignerEmail(item.assigner, item)"
      >
        {{ item.tag.name }} | {{ formatDateTime(item.createdAt) }}
      </b-badge>
    </div>

  </div>
</template>
<script>
import {
  BBadge, VBTooltip, BButton,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { formatDateTime } from '@/plugins/formaters'
import UserAPI from '@/api/user'

export default {
  components: {
    BBadge,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tags: {
      type: Array,
      default() { return [] },
    },
    extrasTags: {
      type: Array,
      default() { return [] },
    },
    extrasVariant: {
      type: String,
      default: 'light-primary',
    },
    showHiddenByToggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingAssigner: false,
      showHidden: false,
    }
  },
  computed: {
    ...get('app', ['showTags']),
    visibleTags() {
      return this.tags.filter(item => item.tag.visible === true)
    },
    invisibleTags() {
      return this.tags.filter(item => item.tag.visible === false)
    },
  },
  methods: {
    formatDateTime,
    getAssignerEmail(id, item) {
      if (typeof item.assigner !== 'string') return

      this.loadingAssigner = true
      UserAPI.getUser(id).then(response => {
        item.assigner = { id, email: response.user_data.email }
      })
        .finally(() => {
          this.loadingAssigner = false
        })
    },
  },
}
</script>
