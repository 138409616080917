<template>
  <div>
    <b-card>
      <b-card-title v-if="title">
        {{ title }}
      </b-card-title>
      <div class="d-flex">
        <h4>Filters</h4>

        <b-button
          type="submit"
          variant="primary"
          size="sm"
          class="ml-auto d-block"
          @click="reloadAll(true)"
        >
          Force reload data
        </b-button>
      </div>
      <table-filter
        :filters="filters"
        :toggle-filters="toggleFilters"
        :toggle-filters-after="toggleFiltersAfter"
        @submit="onFilterSubmit"
        @reset="onFilterReset"
      />
    </b-card>
    <b-overlay
      :show="loading"
      variant="transparent"
      blur="2px"
      rounded="sm"
    >
      <b-table
        :items="data"
        :fields="tableFields"
        striped
        responsive
        small
        no-local-sorting
        :style="data.length < 8 && data.length !== 0 ? 'padding-bottom: 40rem' : ''"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="onSortingChanged"
      >
        <template
          v-for="(_, slotName) of $scopedSlots"
          v-slot:[slotName]="scope"
        >
          <slot
            :name="slotName"
            v-bind="scope"
          />
        </template>
        <template #cell(actions)="data">
          <action-list
            :data="data.item"
            :items="rowActions"
            v-on="$listeners"
          />
        </template>
      </b-table>
    </b-overlay>
    <b-row class="justify-content-between">
      <b-col>
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
            @input="onPerPageChange"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
          @input="onPageChange"
        />
      </b-col>
      <b-col class="text-right">
        Result {{ perPage*(currentPage-1)+1 }} - {{ Math.min(perPage*(currentPage), totalRows) }} of total {{ totalRows }} rows
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination, BOverlay, BCard, BButton, BCardTitle,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import TableFilter from './Filter.vue'
import ActionList from '@/components/ui/ActionList.vue'
import generateGraphQLQuery from '@/plugins/gqlGenerator'

const pageOptions = [5, 10, 25, 50, 100]

export default {
  components: {
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BOverlay,
    ActionList,
    TableFilter,
    BCard,
    BButton,
  },
  props: {
    slots: Array,
    fields: Array,
    query: Array,
    queryType: String,
    actions: {
      default: null,
      type: Array,
      required: false,
    },
    prefilter: {
      default: () => ([]),
      type: Array,
      required: false,
    },
    deletable: {
      default: false,
      type: Boolean,
      required: false,
    },
    sortBy: {
      type: String,
      default: null,
      required: false,
    },
    sortDesc: {
      type: Boolean,
      default: false,
      required: false,
    },
    toggleFilters: {
      type: Boolean,
      default: false,
      required: false,
    },
    toggleFiltersAfter: {
      type: Number,
      default: 6,
    },
    title: {
      type: String,
      default: null,
    },
    filterName: {
      type: String,
      default: null,
    },
    perPageMax: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      data: [],
      filters: null,
      defaultFilters: null,
      totalRows: null,
      currentPage: 1,
      perPage: 25,
      pageOptions,
    }
  },
  computed: {
    ...get('gqlFilter', { defaultFilter: 'filters' }),
    getPerPage() {
      return this.perPageMax > 0 ? this.perPageMax : this.perPage
    },
    tableFields() {
      const fields = this.fields.filter(item => item.visible !== false).map(item => (typeof item === 'string' ? item : { key: item.name, label: item.label, sortable: item.sortable }))
      return this.actions ? [...fields, {
        key: 'actions',
        sortable: false,
      }] : fields
    },
    generatedQuery() {
      const whereFilter = this.filters ? this.filters.map(item => {
        let value
        if (Array.isArray(item.value)) {
          value = item.value.length === 0 ? null : item.value.join(',')
        } else {
          value = item.value
        }
        return { name: item.name, value }
      }).filter(item => item.value !== null) : []
      const query = [{}]
      query[0][this.queryType] = {
        args: [
          { name: 'limit', value: '$limit' },
          { name: 'offset', value: '$offset' },
          { name: 'sort', value: [{ name: 'dir', value: '$sortDir' }, { name: 'field', value: '$sortField' }] },
          { name: 'where', value: [...whereFilter, ...this.prefilter] },
        ],
        fields: [
          {
            nodes: {
              fields: this.query,
            },
          },
          ...(!this.totalRows ? ['totalRows'] : []),
        ],
      }

      return query
    },
    rowActions() {
      const { actions } = this
      if (this.deletable) {
        const action = actions.find(item => item.emit === 'remove')
        if (action) return actions
        actions.push({
          text: 'Remove row',
          icon: 'Trash2Icon',
          emit: 'remove',
        })
      }
      return actions
    },
  },
  async mounted() {
    if (this.perPageMax > 0) {
      this.perPage = this.perPageMax
    }

    this.filters = this.fields.reduce((result, item) => {
      if (typeof item === 'object' && item !== null && item.filterable) {
        result.push({ ...item, value: item.default ?? null })
      }
      return result
    }, [])

    await this.selectFilter()

    this.defaultFilters = JSON.parse(JSON.stringify(this.filters))
    this.reloadAll()
  },
  methods: {
    selectFilter() {
      if (!this.filterName) return

      if (typeof this.defaultFilter[this.filterName] === 'undefined') {
        dispatch('gqlFilter/addFilter', { filter: this.filters, type: this.filterName })
      } else {
        this.filters = this.defaultFilter[this.filterName]
      }
    },
    onFilterSubmit() {
      this.totalRows = null
      this.reloadAll()
    },
    onFilterReset() {
      this.filters = this.defaultFilters
      this.reloadAll()
    },
    onSortingChanged(ctx) {
      if (ctx.sortBy) {
        this.sortBy = ctx.sortBy
        this.sortDesc = ctx.sortDesc
        this.reloadAll()
      }
    },
    reloadAll(force = false) {
      this.loading = true
      this.$apollo.query({
        query: generateGraphQLQuery(this.generatedQuery, `get${this.queryType}`, {
          limit: 'Int', offset: 'Int', sortField: 'String', sortDir: 'String',
        }),
        variables: {
          limit: this.getPerPage,
          offset: this.getPerPage * (this.currentPage - 1),
          sortField: this.sortBy,
          sortDir: this.sortDesc ? 'DESC' : 'ASC',
        },
        fetchPolicy: force ? 'network-only' : 'cache-first',
      }).then(result => {
        this.loading = false
        this.data = result.data[this.queryType].nodes
        if (!this.totalRows) {
          this.totalRows = result.data[this.queryType].totalRows
        }
      })
    },
    onPageChange() {
      this.reloadAll()
    },
    onPerPageChange() {
      this.reloadAll()
    },
  },
}
</script>
