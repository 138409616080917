<template>
  <b-form-group :label="`${filter.label}`">
    <b-form-checkbox
      v-model="filter.value"
    >
      {{ filterLabel }}
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    type: String,
    filter: Object,
  },
  computed: {
    filterLabel() {
      if (this.filter.filterable.labels) {
        return this.filter.value ? this.filter.filterable.labels.true : this.filter.filterable.labels.false
      }

      return this.filter.value ? 'True' : 'False'
    },
  },
}
</script>
