<template>
  <entity-form
    :id="account ? account.id : null"
    :module-name="moduleName"
    :model="form"
    :automatic-reload="automaticReload"
    :before-submit="beforeSubmit"
    @submit="onSubmit"
  >
    <!--  order  -->
    <entity-select
      v-model="form.order"
      :filter="orderFilter"
    />

    <!--  parent account  -->
    <entity-select
      v-model="form.parent"
      :filter="parentFilter"
    />

    <b-form-group
      label="Tags"
    >
      <b-badge
        v-for="(item, index) in form.tags"
        :key="index"
        class="m-50"
        :variant="item.tag.color"
      >
        {{ item.tag.name }}
        <b-button
          class="btn-icon rounded-circle"
          size="sm"
          :variant="item.tag.color"
          @click="removeTag(item.tag.id)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </b-badge>
      <custom-select
        module-name="tagsList"
        label="name"
        not-reduce-by-id
        @input="addTag"
      />
    </b-form-group>

    <b-form-group class="mt-1">
      <label>Comment</label>
      <b-form-textarea
        v-model="form.comment"
        placeholder="Comment"
        rows="8"
      />
    </b-form-group>
    <b-form-group
      label="End datetime"
    >
      <flat-pickr
        v-model="form.end_datetime"
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
      />
    </b-form-group>
    <b-form-group
      label="Rule inheritance"
    >
      <v-select
        v-model="form.rule_inheritance"
        multiple
        :options="INHERITANCE_RULES"
      />
    </b-form-group>
    <b-form-group
      label="Special rules"
    >
      <v-select
        v-model="form.rule_special"
        multiple
        :options="Object.keys(ACCOUNT_SPECIAL_RULES)"
      />
    </b-form-group>
    <b-form-group
      label="Special options"
    >
      <v-select
        v-model="form.special_options"
        multiple
        :options="ACCOUNT_SPECIAL_OPTIONS"
      />
    </b-form-group>
    <b-row>
      <b-col
        v-for="(label, rule) in rulesWithInput"
        class="col-6"
      >
        <b-form-group
          :label="label === 'Max initial DD' ? `${label} (current DD: ${currentTDrawdown}%)` : label"
        >
          <b-form-input
            v-model.number="form[rule]"
          />
        </b-form-group>
      </b-col>

    </b-row>
  </entity-form>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BFormGroup, BBadge, BButton, BFormTextarea, BFormInput, BCol, BRow,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import vSelect from 'vue-select'
import FlatPickr from 'vue-flatpickr-component'
import { omit } from 'lodash'
import EntityForm from '@/components/ui/forms/EntityForm.vue'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import {
  ACCOUNT_RULES, ACCOUNT_SPECIAL_RULES, SPECIAL_OPTIONS, INHERITANCE_RULES,
} from '@/enums/accounts'
// import EntityFilter from '@/components/ui/gqltables/filters/EntityFilter.vue'
import EntitySelect from '@/components/ui/gqlforms/EntitySelect.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    EntitySelect,
    // EntityFilter,
    EntityForm,
    CustomSelect,
    vSelect,
    FlatPickr,
    BFormGroup,
    BBadge,
    BButton,
    BFormTextarea,
    BFormInput,
    BCol,
    BRow,
  },
  props: {
    account: {
      required: false,
      type: Object,
    },
    moduleName: String,
    automaticReload: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      ACCOUNT_RULES,
      INHERITANCE_RULES,
      form: {
        order: this.account ? this.account.order : null,
        parent: this.account ? this.account.parent : null,
        tags: this.account ? this.account.tags : [],
        comment: this.account ? this.account.comment : null,
        rule_inheritance: this.account ? this.account.rule_inheritance : null,
        rule_special: this.account ? this.account.rule_special : null,
        special_options: this.account ? this.account.special_options : null,
        rule_max_days: this.account ? this.account.rule_max_days : null,
        rule_max_daily_drawdown: this.account ? this.account.rule_max_daily_drawdown : null,
        rule_max_total_drawdown: this.account ? this.account.rule_max_total_drawdown : null,
        rule_min_trading_days: this.account ? this.account.rule_min_trading_days : null,
        rule_min_profit: this.account ? this.account.rule_min_profit : null,
        rule_first_withdrawal_after: this.account ? this.account.rule_first_withdrawal_after : null,
        rule_withdrawal_after: this.account ? this.account.rule_withdrawal_after : null,
        profit_split: this.account ? this.account.profit_split : null,
        // eslint-disable-next-line no-nested-ternary
        end_datetime: this.account ? (this.account.end_datetime !== null ? new Date(this.account.end_datetime).toISOString() : null) : null,
      },
      invalid: false,
      submitting: false,
      parentFilter: {
        name: 'parent',
        label: 'Parent account',
        filterable: {
          type: 'entity', queryType: 'accounts', label: 'account_id', search: 'account_id', value: 'id',
        },
      },
      orderFilter: {
        name: 'order',
        label: 'Order',
        filterable: {
          type: 'entity', queryType: 'orders', label: 'order_number', search: 'order_number', value: 'id',
        },
      },
    }
  },
  computed: {
    ACCOUNT_SPECIAL_RULES() {
      return ACCOUNT_SPECIAL_RULES
    },
    ACCOUNT_SPECIAL_OPTIONS() {
      return SPECIAL_OPTIONS
    },
    ...get('auth', ['me']),
    rulesWithInput() {
      return omit(ACCOUNT_RULES, 'rule_special')
    },
    currentTDrawdown() {
      if (!this.account) {
        return null
      }
      return this.account.rule_max_total_drawdown + this.account.withdrawal_count < 14 ? this.account.rule_max_total_drawdown + this.account.withdrawal_count : 14
    },
  },
  mounted() {
    dispatch('auth/getMe')
  },
  methods: {
    beforeSubmit() {
      this.form.order = this.form.order?.id || this.form.order
      this.form.parent = this.form.parent?.id || this.form.parent
      this.form.tags = this.form.tags.map(item => ({ ...item, assigner: typeof item.assigner === 'string' || item.assigner === null ? item.assigner : item.assigner.id }))
      this.form.end_datetime = this.form.end_datetime !== null ? new Date(this.form.end_datetime).toISOString() : null

      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
    addTag(item) {
      if (!this.form.tags.find(tagToAccount => tagToAccount.tag.id === item.id)) this.form.tags.push({ tag: item, account: this.account.id, assigner: this.me.id })
    },
    removeTag(id) {
      this.form.tags = this.form.tags.filter(item => item.tag.id !== id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
