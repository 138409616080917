<template>
  <b-form @submit.prevent="onFilterSubmit">
    <b-row>
      <b-col
        v-for="filter in visibleFilters"
        class="col-md-3 col-lg-2 col-sm-6 col-12"
      >
        <component
          :is="getFilterComponent(filter)"
          v-model="filter.value"
          :filter="filter"
          :type="getFilterType(filter)"
        />
      </b-col>
    </b-row>
    <b-button
      type="submit"
      variant="primary"
      size="sm"
      class="mr-2"
    >
      Search
    </b-button>
    <b-button
      type="button"
      variant="danger"
      class="mr-2"
      size="sm"
      @click="onReset"
    >
      Reset
    </b-button>
    <b-button
      v-if="toggleFilters"
      type="button"
      variant="info"
      size="sm"
      @click="showAll = !showAll"
    >
      {{ showAll ? 'Less' : 'More' }} filters
    </b-button>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BButton, BForm,
} from 'bootstrap-vue'
import TextFilter from './filters/TextFilter.vue'
import EnumFilter from './filters/EnumFilter.vue'
import EntityFilter from './filters/EntityFilter.vue'
import RangeFilter from './filters/RangeFilter.vue'
import BooleanFilter from './filters/BooleanFilter.vue'
import DateRangeFilter from './filters/DateRangeFilter.vue'

export default {
  components: {
    BRow, BCol, BButton, BForm,
  },
  props: {
    filters: Array,
    toggleFilters: {
      type: Boolean,
      default: false,
      required: false,
    },
    toggleFiltersAfter: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      showAll: false,
    }
  },
  computed: {
    visibleFilters() {
      const f = this.filters ? this.filters.filter(f => typeof f.filterable.visible === 'undefined' || f.filterable.visible === true) : []
      return this.toggleFilters && !this.showAll ? f.slice(0, this.toggleFiltersAfter) : f
    },
  },
  methods: {
    getFilterType(filter) {
      return typeof filter.filterable === 'string' ? filter.filterable : filter.filterable.type
    },
    getFilterComponent(filter) {
      const type = this.getFilterType(filter)
      switch (type) {
        case 'eq':
          return TextFilter
        case 'like':
          return TextFilter
        case 'is':
          return BooleanFilter
        case 'range':
          return RangeFilter
        case 'daterange':
          return DateRangeFilter
        case 'multientity':
          return EntityFilter
        case 'entity':
          return EntityFilter
        case 'enum':
          return EnumFilter
        case 'multienum':
          return EnumFilter
        default:
          return TextFilter
      }
    },
    onFilterSubmit() {
      this.$emit('submit')
    },
    onReset() {
      this.$emit('reset')
    },
  },
}
</script>
