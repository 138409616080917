<template>
  <phases-table />
</template>
<script>
import PhasesTable from '@/components/entity/account-gql/PhasesTable.vue'

export default {
  components: { PhasesTable },

}
</script>
