<template>
  <b-form-group :label="filter.label">
    <v-select
      :value="selectedItem"
      :label="filter.filterable.label"
      :options="data"
      :class="selectClass"
      @search="onChange"
      @input="onInput"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import generateGraphQLQuery from '@/plugins/gqlGenerator'

export default {
  components: {
    BFormGroup, vSelect,
  },
  props: {
    type: String,
    filter: Object,
    value: [Object, String],
    selectClass: {
      type: String,
      default: 'select-size-md',
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      searchValue: null,
      defaultItem: { id: null, [this.filter.filterable.label]: null },
      selectedItem: { ...this.defaultItem },
    }
  },
  computed: {
    generatedQuery() {
      const whereFilter = this.searchValue ? [{ name: this.filter.filterable.search, value: this.searchValue }] : []
      const query = [{}]
      query[0][this.filter.filterable.queryType] = {
        args: [
          { name: 'limit', value: '$limit' },
          { name: 'where', value: whereFilter },
        ],
        fields: [
          {
            nodes: {
              fields: [this.filter.filterable.label, this.filter.filterable.value],
            },
          },
        ],
      }

      return query
    },
  },
  watch: {
    searchValue: {
      immediate: true,
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        this.reloadAll()
      }, 500),
    },
  },
  beforeMount() {
    this.selectedItem = this.value ? { ...this.value } : { ...this.defaultItem }
  },
  mounted() {
    this.reloadAll()
  },
  methods: {
    onChange(search) {
      this.searchValue = search
    },
    reloadAll() {
      this.loading = true
      this.$apollo.query({
        query: generateGraphQLQuery(this.generatedQuery, `get${this.filter.filterable.queryType}`, { limit: 'Int' }),
        variables: {
          limit: 15,
        },
      }).then(result => {
        this.data = result.data[this.filter.filterable.queryType].nodes
      }).finally(() => {
        this.loading = false
      })
    },
    onInput(item) {
      if (!item) {
        this.$emit('input', item)
        this.selectedItem = this.defaultItem
        return
      }
      this.selectedItem = item
      this.$emit('input', item[this.filter.filterable.value])
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
