<template>
  <b-badge
    v-b-tooltip.hover.top="scores"
    :variant="score > 5 ? 'light-danger' : 'light-success' "
  >
    {{ score }}
  </b-badge>
</template>
<script>
import { BBadge, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    score: Number,
    scoreResults: Object,
  },
  computed: {
    scores() {
      let scores = ''

      Object.keys(this.scoreResults).forEach(key => {
        scores += `${key.replaceAll('_', ' ').toUpperCase()}:   ${this.scoreResults[key]} | `
      })
      return scores
    },
  },
}
</script>
