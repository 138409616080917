<template>
  <b-dropdown
    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
    variant="flat-dark"
    no-caret
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
      />
    </template>
    <b-dropdown-item
      v-for="item in items"
      :disabled="item.disabled"
      :class="item.textClass"
      @click="onActionClick(item.emit)"
    >
      <feather-icon
        v-if="item.icon"
        :icon="item.icon"
        size="12"
        class="mr-25"
      />
      {{ item.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BDropdownItem,
    BDropdown,
  },
  directives: {
    Ripple,
  },
  props: {
    items: Array,
    data: {
      required: false,
      type: Object,
      default: null,
    },
  },
  methods: {
    onActionClick(emit) {
      this.$emit(emit, this.data)
    },
  },
}
</script>
