<template>
  <div>
    <b-row class="mb-1">
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>ID: </strong>{{ row.item.id }}
      </b-col>

      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Account type: </strong>{{ row.item.account_type_name }} {{ row.item.account_subtype_name }}
      </b-col>

      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Account number: </strong>{{ row.item.account_id }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Password: </strong>{{ row.item.password }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Investor password: </strong>{{ row.item.investor_password }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Deposit / Balance: </strong>{{ formatCurrency(row.item.deposit, row.item.currency.label) }} / {{ formatCurrency(row.item.balance, row.item.currency.label) }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Created at: </strong>{{ formatDateTime(row.item.createdAt) }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Start date: </strong>{{ formatDateTime(row.item.start_datetime) }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>End date: </strong>{{ formatDateTime(row.item.end_datetime) }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>State: </strong>{{ row.item.state }}
      </b-col>

      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Server: </strong>{{ row.item.server.name }}
      </b-col>

      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Comment: </strong>{{ row.item.comment }}
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col class="col-3">
        <strong>max_days</strong>: {{ row.item.rule_max_days }}
      </b-col>
      <b-col class="col-3">
        <strong>max_daily_drawdown</strong>: {{ row.item.rule_max_daily_drawdown }}
      </b-col>
      <b-col class="col-3">
        <strong>max_total_drawdown</strong>: {{ row.item.rule_max_total_drawdown }}
      </b-col>
      <b-col class="col-3">
        <strong>min_trading_days</strong>: {{ row.item.rule_min_trading_days }}
      </b-col>
      <b-col class="col-3">
        <strong>min_profit</strong>: {{ row.item.rule_min_profit }}
      </b-col>
      <b-col class="col-3">
        <strong>close_at_end</strong>: {{ row.item.rule_close_at_end }}
      </b-col>
      <b-col class="col-3">
        <strong>first_withdrawal_after</strong>: {{ row.item.rule_first_withdrawal_after }}
      </b-col>
      <b-col class="col-3">
        <strong>withdrawal_after</strong>: {{ row.item.rule_withdrawal_after }}
      </b-col>
    </b-row>

    <b-button
      size="sm"
      variant="outline-secondary"
      @click="row.toggleDetails"
    >
      Hide Details
    </b-button>
  </div>
</template>

<script>
import { BCol, BRow, BButton } from 'bootstrap-vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  props: {
    row: Object,
  },
  methods: {
    formatDateTime,
    formatCurrency,
  },
}
</script>
