import axiosInstance from '@/plugins/axios'

export default {
  promoteUserToPartner(userId) {
    const url = `admin/users/${userId}/promote-partner`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  getUser(userId) {
    const url = `admin/users/${userId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  resetTFA(userId) {
    const url = `admin/users/${userId}/tfa-reset`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  postTagsToUsers(data) {
    const url = 'admin/users/tags'

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  deleteUsers(data) {
    const url = 'admin/users/delete'

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  deleteUser(userId) {
    const url = `admin/users/${userId}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  promoteUsersToPartner(data) {
    const url = 'admin/users/promote-partner'

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  generateVeriffReport(id, sessionId, email) {
    const url = `admin/users/veriff/${id}/report`

    return axiosInstance.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${email}-${sessionId}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  },

  getVeriffImage(verificationId, imgId) {
    const url = `admin/users/veriff/${verificationId}/image/${imgId}`

    return axiosInstance.get(url, {
      responseType: 'arraybuffer',
    })
      .then(response => Buffer.from(response.data, 'binary').toString('base64'))
  },

  getUserPassRates(userId) {
    const url = `admin/users/${userId}/pass-rate`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getUserPL(userId) {
    const url = `admin/users/${userId}/pl`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  postAccountSettingsToUser(userId, data) {
    const url = `admin/users/${userId}/account-type-settings`

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  deleteAccountSettingsToUser(id) {
    const url = `admin/users/account-type-settings/${id}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  getCertificate(email, userId, size = 'small', certificateType = 'social', forceGenerate = false) {
    const url = `admin/users/${userId}/certificate`

    return axiosInstance.get(url, {
      responseType: 'blob',
      params: { size, certificateType, forceGenerate },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${email}.${size === 'print' ? 'pdf' : 'jpg'}`) // or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  postLoginAs(userId) {
    const url = `admin/users/${userId}/login-as`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

}
