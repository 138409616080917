<template>
  <b-form-group :label="`${filter.label} ${type==='enum' ? 'equals' : 'in'}`">
    <v-select
      v-model="filter.value"
      label="label"
      :options="options"
      :multiple="type==='multienum'"
      :reduce="item => item.value"
      class="select-size-sm"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup, vSelect,
  },
  props: {
    type: String,
    filter: Object,
  },
  computed: {
    options() {
      if (this.filter.filterable.enum && typeof this.filter.filterable.enum[0] === 'string') {
        return this.filter.filterable.enum.map(item => ({ label: item, value: item }))
      } return this.filter.filterable.enum
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
